<template>
    <Page v-bind="$props" title="我的" navbar large transparent tab-root>
        <template #nav-title-large>
            <div class="flex justify-between">
                我的
                <f7Link href="/user/settings"><img src="./assets/icon_setting.svg" alt=""></f7Link>
            </div>
        </template>
        <f7BlockTitle>个人信息</f7BlockTitle>
        <RoundDesc v-for="item in userInfo.value?.valueList" class="mx-4 my-2" :name="item.key.padEnd(6, '　')">
            {{ item.value }}</RoundDesc>
        <RoundDesc v-if="AlipayMarketingFlag" @click="toAlipayQr()" class="mx-4 my-2" :name="'花呗推广码'.padEnd(6, '　')">{{ AlipayMarketingText }}</RoundDesc>
        <RoundDesc v-if="userInfo.value?.staticCodeUrl" @click="toStaticCodeQr(userInfo.value?.staticCodeUrl)" class="mx-4 my-2" :name="'静态码'.padEnd(6, '　')">已开通</RoundDesc>
        <div class="relative" v-if="userInfo.value?.showWallet">
            <f7BlockTitle>账户信息</f7BlockTitle>
            <div class="wallet" @click="toWallet()">我的钱包
                <div class="icon-cell"></div>
            </div>
            <RoundDesc @click="toAccountSetting()" class="mx-4 my-2" :name="'提现账号'.padEnd(6, '　')">{{ accountInfo.value?.account ?  accountInfo.value?.account : '去设置' }}</RoundDesc>
            <RoundDesc class="mx-4 my-2" :name="'钱包余额'.padEnd(6, '　')">{{ accountInfo.value?.amount }}</RoundDesc>
        </div>
    </Page>
</template>

<script setup lang="ts">
import { f7BlockTitle, f7Link } from 'framework7-vue';
import { Router } from 'framework7/types';
import Page from 'src/components/Pages/Page.vue';
import RoundDesc from 'src/components/RoundControls/RoundDesc.vue';
import { sharedLogin } from 'src/Controllers/LoginConrtoller';
import { makeLoader } from 'src/Loader';
import * as api from '../api';
import { getCurrentInstance , ref } from 'vue';
const props = defineProps<{
    f7router: Router.Router
    f7route: Router.Route
}>();

const login = sharedLogin
const userInfo = makeLoader(api.getUserInfo).reactive()
userInfo.load()

let AlipayMarketingFlag = ref(false)
let AlipayMarketingText = ref('')

const AlipayMarketingState:any = makeLoader(api.getAlipayMarketingState).reactive()
AlipayMarketingState.load().then(()=>{
    setAlipayMarketingText()
})


const instance:any = getCurrentInstance();
instance?.proxy?.$bus.on('wallet',() => {
    accountInfo.load();
})


instance?.proxy?.$bus.on('alipayMarketing',() => {
    AlipayMarketingState.load().then(()=>{
        setAlipayMarketingText()
    })
})

const accountInfo:any = makeLoader(api.getAccountInfo).reactive()
accountInfo.load()

//我的钱包
const toWallet=()=>{
    props.f7router.navigate('/user/wallet');
}

//我提现账户设置
const toAccountSetting=()=>{
    if(!accountInfo.value?.account){
        props.f7router.navigate('/user/accountSetting');
    }
}

//花呗推广码
const toAlipayQr=()=>{
    if(AlipayMarketingState.value === -1 || AlipayMarketingState.value == 2){
        props.f7router.navigate('/user/AlipayQr/index');
    }
}

//
const toStaticCodeQr=(url:string)=>{
    props.f7router.navigate(`/user/stateCodeQr`,{props:{url:url}});
}

const setAlipayMarketingText = ()=>{
    if(AlipayMarketingState.value === -2){
        AlipayMarketingFlag.value = false
    }else if(AlipayMarketingState.value === -1){
        AlipayMarketingText.value = '去上传'
        AlipayMarketingFlag.value = true
    }else if(AlipayMarketingState.value == 0){
        AlipayMarketingText.value = '审核中'
        AlipayMarketingFlag.value = true
    }else if(AlipayMarketingState.value == 1){
        AlipayMarketingText.value = '已上传'
        AlipayMarketingFlag.value = true
    }else if(AlipayMarketingState.value == 2){
        AlipayMarketingText.value = '被驳回'
        AlipayMarketingFlag.value = true
    }
}

</script>

<style scoped>
.wallet{
    font-family: PingFang SC;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    position: absolute;
    top: 35px;
    right: 15px;
    color: var(--f7-theme-color);
}
.icon-cell {
    color: var(--f7-theme-color);
    background-color: currentColor;
    mask-image: url(../../收款/活动收款/assets/arrows.svg);
    width: 6px;
    height: 8px;
    margin: auto;
    margin-left: 4px;
}
</style>