import { reactive } from "vue";
import * as api from "../pages/登录/api";
import QueryString from "qs";
import { wxsdk } from "src/modules/Wxsdk";
import { inWeixin } from "src/modules/EnvironmentDetect";

function getToken(): string | null {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    if (query.token) {
        localStorage.setItem('token', query.token as string)
        return query.token as string
    } else {
        // if (inWeixin()) {
        //     return null
        // } else {
            return localStorage.getItem('token')
        // }
    }
}

function getPathname(): string {
    console.log(window.location.pathname,'22222222222');
    return window.location.pathname
    // const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    // return query.channelCode as string | null
}

function getChannelCode(): string | null {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    return query.channelCode as string | null
}

function getProjectCode(): string | null {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    return query.projectCode as string | null
}

function getAppId(): string | null {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    return query.appId as string | null
}

function getUid(): string | null {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    return query.uid as string | null
}

function getInvitationCode(): string | null {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    return query.invitationCode as string | null
}

function getCode(): string | null {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    return query.code as string | null
}

function getStoreNo(): string | null {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    return query.storeNo as string | null
}

function getRefundTradeNo(): string | null {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    return query.refundTradeNo as string | null
}

function getVersions(): string | null {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    return query.versions as string | null
}

function getChannelShow(): boolean | null{ 
    const channelShow = localStorage.getItem('channelShow') === 'true'
    return channelShow as boolean | null
}

function getPageType(): string | null{ 
    const pageType = localStorage.getItem('pageType')
    
    return pageType as string | null
}


function getIsDefault() {
    api.showPackageEntrance().then((res)=>{
        localStorage.setItem("isDefault",JSON.stringify(res))
        return true
    })
}

export class LoginController {
    private _token: string | null = getToken()
    private _user: api.User | null = null

    private _agreePrivacy = localStorage.getItem('agreePrivacy') === 'true'

    get agreePrivacy() {
        return this._agreePrivacy
    }

    set agreePrivacy(val) {
        localStorage.setItem('agreePrivacy', val ? 'true' : 'false')
        this._agreePrivacy = val
    }

    get token() {
        return this._token
    }

    get user() {
        return this._user?this._user:JSON.parse(localStorage.getItem("user"))
    }

    get isLogin() {
        return !!this._token
    }

    get shouldManualEntry() {
        let accountType
        if(this._user){
            accountType = this._user.accountType
        }else{
            try {
                accountType = JSON.parse(localStorage.getItem("user"))?.accountType
            }catch (e){

            }
        }
        return getChannelCode()!=null&&accountType==6
    }

    get userType() {
        let accountType
        if(this._user){
            accountType = this._user.accountType
        }else{
            try {
                accountType = JSON.parse(localStorage.getItem("user"))?.accountType
            }catch (e){

            }
        }
        return accountType
    }

    get channelCode() {
        return getChannelCode()
    }

    get projectCode(){
        return getProjectCode()
    }

    get appId(){
        return getAppId()
    }

    get uid(){
        return getUid()
    }

    get invitationCode(){
        return getInvitationCode()
    }

    get channelShow(){
        return getChannelShow()
    }

    get pageType(){
        return getPageType()
    }

    get refundTradeNo(){
        return getRefundTradeNo()
    }

    get versions(){
        return getVersions()
    }
    
    get pathname() {
        return getPathname()
    }


    get getUrl(){
        let obj = {type:1,path:''};
        //1-登录页 2-登录后的页面 3-静态码页面
        let pathname = window.location.pathname;
        if(pathname && pathname.indexOf('/staticCode/') > -1){
            obj.type = 3;
            obj.path = pathname
        }else{
            if(!!this._token){
                obj.type = 2
            }
        }
        return obj;
    }

    code() {
        return getCode()
    }
    storeNo() {
        return getStoreNo()
    }
    fromWechat() {
        return inWeixin()
    }
    // isDefault(){
    //     return getIsDefault()
    // }
    async setLogin(user: api.User | null) {
        this._user = user
        if (user) {
            this._token = user.token
            localStorage.setItem('token', user.token)
            localStorage.setItem('user', JSON.stringify(user))
        } else if (this._token) {
            this._token = null
            let wx;
            try{
                wx = await wxsdk()
            }catch(e){

            }
            localStorage.clear()
            wx && wx.miniProgram?.reLaunch({ url: '/pages/index/index?resetToken=true' })
        }
    }

    async toWxLogin(){
        this._token = null
        const wx = await wxsdk()
        localStorage.clear()
        wx.miniProgram?.reLaunch({ url: '/pages/index/index?resetToken=true' })
    }


    askAgreePrivacy(): Promise<void> {
        return new Promise((resolve, reject) => {
            const dialog = window.f7.dialog.confirm(`
            欢迎使用${getVersions() === 'ZanFu' ? '赞付' : '京聚汇'}APP，我们非常重视您的个人信息和隐私保护，在您使用本服务之前，请您务必审慎阅读<a class="link privacy-link">《隐私政策》</a>，并充分理解条款内容。我们将严格按照您同意的各项条款使用您的个人信息，以便为您提供更好的服务。如您不同意我们的政策，则无法继续使用我们的服务。
            `, '系统提示',
                () => resolve(undefined),
                () => reject(new Error('用户未同意隐私政策'))
            )
            dialog.open()
            dialog.el.querySelector('.privacy-link')?.addEventListener('click', () => {
                dialog.close()
                window.f7.views.login.router.navigate('/privacy')
                reject(new Error('用户未同意隐私政策'))
            })
        })
    }

    askAgreePrivacyIfNeeds(): Promise<void> {
        if (this.agreePrivacy) {
            return Promise.resolve()
        }
        return this.askAgreePrivacy().then(() => {
            this.agreePrivacy = true
        })
    }
}

export const sharedLogin = reactive(new LoginController())
