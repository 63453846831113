import { FieldDesc } from "src/components/DynamicFields/DynamicFields.vue";
import { resultData , http } from "src/http";


export type UserInfo = {
  "accountType": number,
  "desc": "",
  "staticCodeUrl":string,
  "showWallet":boolean,
  "valueList": FieldDesc[]
}

export function getUserInfo() {
  return resultData.post<UserInfo>('/jhserver/user/app/v1/getUserInfoList')
}

export function getBindPhone() {
    return resultData.post('/jhserver/user/app/v1/getUserBindPhone')
}

export function unBindPhone(smsVerifyCode: string) {
    return resultData.post('/jhserver/user/app/v2/unBindPhone', {smsVerifyCode})
}

export function getUnBindPhoneSms() {
    return resultData.post('/jhserver/user/app/v2/getUnBindPhoneSmsVerifyCode')
}

export function bindPhone(phone: string,smsVerifyCode: string) {
    return resultData.post('/jhserver/user/app/v1/bindPhone', {phone,smsVerifyCode})
}

export function getBindPhoneSms(phone: string) {
    return resultData.post('/jhserver/user/app/v1/getBindPhoneSmsVerifyCode',{phone})
}

export function getBindPhoneFlag() {
    return resultData.post('/jhserver/user/app/v1/getBindPhoneFlag',)
}

export function getChangeBindPhoneSms(phone: string) {
    return resultData.post('/jhserver/user/app/v1/getChangeBindPhoneSmsVerifyCode',{phone})
}

export function changeBindPhone(phone: string,smsVerifyCode: string) {
    return resultData.post('/jhserver/user/app/v1/changeBindPhone',{phone,smsVerifyCode})
}

export function changePassword(oldPassword: string,password: string) {
    return resultData.post('/jhserver/user/app/v1/changePassword',{oldPassword,password})
}

export function getForgetPasswordCode(username: string) {
    return resultData.post('/jhserver/user/app/v1/getForgetPasswordCode',{username})
}

export function forgetPassword(username: string,smsCode: string,password: string) {
    return resultData.post('/jhserver/user/app/v1/forgetPassword',{username,smsCode,password})
}

export function logout() {
  return resultData.post<string>('/jhserver/user/app/v1/logout')
}

//账户信息
export function getAccountInfo() {
    return resultData.post<any>('/jhserver/user/wallet/app/v1/detail')
}

//钱包明细
export function getAccountInfoList(nextId?:string) {
    return resultData.post('/jhserver/user/wallet/app/v1/list',{nextId})
}

//提现验证码短信
export function getSendSmsCode() {
    return http.json.post('/jhserver/user/wallet/app/v1/sendSmsCode')
}

//设置提现账户
export function setSaveWalletAccount(params:any) {
    return http.json.post('/jhserver/user/wallet/app/v1/saveWalletAccount',params)
}

//提现
export function withdraw(params:any) {
    return http.json.post('/jhserver/user/wallet/app/v1/withdraw',params)
}

//提现详情
export function getWithdrawInfo(params:any) {
    return http.json.post('/jhserver/user/wallet/app/v1/withdrawInfo',params)
}

//钱包充值
export function setCharge(params:any) {
    return http.json.post('/jhserver/user/wallet/app/v1/charge',params)
}

//充值结果
export function chargeResult(params:any) {
    return http.json.post('/jhserver/user/wallet/app/v1/chargeResult',params)
}

//获取支付宝店员分期码状态
export function getAlipayMarketingState() {
    return resultData.post<UserInfo>('/jhserver/index/app/v1/getAlipayMarketingState')
}

//获取支付宝店员分期码商户显示信息
export function getAlipayMarketingCodeInfo() {
    return http.json.post<any>('/jhserver/index/app/v1/getAlipayMarketingCodeInfo')
}

//店员分期码新增
export function alipayMarketingSave(params:any) {
    return http.json.post<any>('/alipay/alipayMarketing/web/v1/save',params)
}